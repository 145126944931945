@use "../../styles/abstracts/variables" as v;
@use "../../styles/abstracts/mixins" as m;

.container {
  @include m.flexCenter();
  width: 100%;
  height: 100%;
}

.logo {
  @include m.flexCenter(column);
  padding: 3rem;
  border-radius: 30px 0 0 30px;
  width: 40%;
  height: 100%;
  background: rgb(197, 198, 199);
  background: linear-gradient(
    90deg,
    rgba(197, 198, 199, 1) 0%,
    rgba(97, 97, 98, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}
.content {
  @include m.flexCenter(column);
  justify-content: space-evenly;
  gap: 1rem;
  color: v.$text-color-dark;
  padding: 2rem;
  width: 60%;

  height: 100%;
  line-height: 1.5;
  & h1 {
    color: v.$text-color-dark;
    font-size: 3.5rem;
  }
}

.inputContainer {
  @include m.flexCenter();
  width: 90%;
  height: 4rem;
  & button {
    height: 100%;
    padding: 0 1rem;
    border: none;
    border-radius: 0 10px 10px 0;
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 0.15rem;
    background-color: v.$color-primary-dark;
  }
}

.input {
  padding: 1rem;
  font-size: 1.7rem;
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  border: none;
  border: 1px solid v.$grey-color-0;

  &:focus {
    border: 1px solid v.$color-primary;
  }
}

.error {
  border: 2px solid rgb(255, 152, 152);
}
